import { useEffect, useState } from 'react'

function useNavHeight() {
  const [navHeight, setNavHeight] = useState(120)

  function handleNavHeight() {
    if (typeof document !== 'undefined') {
      const headerHeight = document
        .getElementById('topNavBar')
        ?.getBoundingClientRect().height

      if (headerHeight !== 'undefined') setNavHeight(headerHeight)
    }
  }

  useEffect(() => {
    handleNavHeight()
  }, [])

  return navHeight
}

export default useNavHeight
