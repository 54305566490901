import React from 'react'
import SbEditable from 'storyblok-react'
import { monochrome } from '@helpers/palette'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import PageContainer from '@system/page-container'
import useNavHeight from '@hooks/use-nav-height'

const useStyles = makeStyles(() => {
  return {
    space: ({ backgroundColor }) => ({
      backgroundColor: monochrome(backgroundColor),
    }),
    anchor: ({ navHeight }) => ({
      display: 'block',
      position: 'relative',
      top: `-${navHeight}px`,
      visibility: 'hidden',
    }),
  }
})
const Spacing = ({ blok, ...props }) => {
  const navHeight = useNavHeight()
  const backgroundColor = props.isMobileNavContent
    ? '#333'
    : !!blok.bgColor && blok.bgColor.color
  const amount = blok.amount ? parseInt(blok.amount, 10) : 0
  const classes = useStyles({ ...blok, backgroundColor, navHeight, amount })

  return (
    <SbEditable content={blok}>
      <PageContainer
        parentWidth={blok.isFullWidth}
        backgroundColor={backgroundColor}
        styles={{ height: 'auto' }}
      >
        {!!blok.anchorId && (
          <Box className={classes.anchor} id={blok.anchorId}></Box>
        )}
        <Box className={classes.space} pt={amount}></Box>
      </PageContainer>
    </SbEditable>
  )
}

export default Spacing
